.side-bar-logo {
  height: 30px;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  object-fit: contain;
}

.side-menu-nav-background {
  background-color: transparent;
}

.side-bar-menu-logo {
  font-family: Mulish;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 18px;

  color: #ffffff;

  width: 230px;
  height: 29px;

  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}
