.download-preview-container {
  margin-top: 36px;
  display: flex;
}
.download-preview-image-container {
  position: relative;
  margin-left: 62px;
  &.first-img {
    margin-left: 0;
  }
}
.download-preview-img {
  width: 257px;
  height: 257px;
}
.preview-img-checkbox {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 40px;
}
