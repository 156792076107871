@import '../../utils/Variables.scss';

.custom-input-with-max-length-short {
  max-width: 619px;
}

.custom-input-with-max-length-with-count-input {
  border: none;
  width: 100%;
  padding: 0;
}

.custom-input-with-max-length-with-count-input:active,
.custom-input-with-max-length-with-count-input:focus {
  border: none;
  box-shadow: none;
  outline: 0px;
}

.custom-input-with-max-length-with-count {
  position: relative;
  width: 100%;
  max-width: 700px;
  padding: 0px 12px;
  height: 40px;
  background: white;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid $lightGreyTwo;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-flex;
}

.custom-input-with-max-length-with-count:active,
.custom-input-with-max-length-with-count:focus-within {
  border: 1px solid #2b84ee;
  box-shadow: 0px 0px 8px rgba(43, 132, 238, 0.5);
  outline: 0px;
}

.custom-input-with-max-length-count {
  flex: none;
  margin: 8px 0 8px 5px;
  opacity: 0.45;
}

.custom-input-with-max-length-with-count-619 {
  max-width: 619px;
}

.tips-message {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  color: #979797;
  white-space: pre-line;
}