.splash-ad-radio {
  align-items: center;
}


.splash-ad-input {
  margin-left: 8px;
  background: white;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  width: 58px;
  text-align: center;
}