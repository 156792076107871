@import '../../utils/Variables.scss';

.list-section-container-header {
  min-height: 116px;
  margin-top: 60px;
  background: #ffffff;
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
  width: 100%;
  flex-direction: column;
  @media screen and (max-width: $hideSideMenuWidth) {
    width: 100%;
  }
}

.list-section-container-header-bottom {
  box-shadow: inset 0px -1px 0px #dadada;
}

.list-section-container-button-and-title {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.list-section-container-header-buttons {
  margin-left: auto;
  margin-right: 0px;
  display: flex;
  flex-direction: row;
  .btn {
    white-space: pre;
  }
}

.list-section-container-header-title {
  font-family: MuliExtraBold;
  font-size: 26px;
  line-height: 33px;
  color: $darkBlue;
  margin-bottom: 0px;
  max-width: 596px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.list-section-container-header-left {
  margin-right: auto;
  max-width: 596px;
}

.list-section-container-content-bar {
  border-bottom: none;
  z-index: 1;
  background-color: white;
  width: 100%;
  padding-left: 40px;
  border-bottom: 1px solid #dadada;
  @media screen and (max-width: $hideSideMenuWidth) {
    width: 100%;
  }
}

.list-section-container-content-bar a.nav-item.nav-link {
  margin-right: 60px;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.all-list-scrollable-area {
  position: relative;
  max-width: $listScrollableAreaWidth;
  padding-left: 40px;
  padding-right: 40px;
  overflow-x: auto;
  @media screen and (max-width: $hideSideMenuWidth) {
    max-width: 100vw;
  }
}

.all-list-table-content {
  position: relative;
  display: inline;
  margin-right: 40px;
}

.all-list-component-action-header {
  // min-width: 1186px;
  width: 100%;
  display: inline-block;
  margin-right: 40px;
}

.all-list-component-action-header-div {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  padding-top: 30px;
  padding-bottom: 30px;
}

.all-list-scrollable-area-filter {
  right: 10px;
  top: 10px;
  z-index: 1;
  position: absolute;
}

.all-list-scrollable-area-table-item-common {
  height: 82px;
  padding: 20px 16px;
}

.all-list-scrollable-area-checkbox-container {
  width: $listCheckbox;
}

input.all-list-scrollable-area-checkbox {
  width: 20px;
  height: 20px;
  margin-top: 5px;
  background: #ffffff;
  border: 1px solid #8c8c8c;
  box-sizing: border-box;
  border-radius: 2px;
}

.all-list-scrollable-area-action-title {
  text-align: center;
}

.all-list-scrollable-area-title {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  color: #4d5560;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.all-list-scrollable-area-text-common {
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-bottom: 0px;
  color: #000000;
  overflow-wrap: anywhere;
}

.all-list-scrollable-area-clickable-field {
  color: #1890ff;
}

.all-list-scrollable-area-unclickable-field {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.all-list-scrollable-area-id-container {
  width: $listId;
}

.all-list-scrollable-area-name {
  min-width: $listNameMin;
  width: $listTwentyFivePercentWidth;
}

.all-list-scrollable-area-name-text,
.all-list-scrollable-area-name-text:hover {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #2b84ee;
  flex: none;
  order: 0;
  align-self: center;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  word-wrap: break-word;
}
.all-list-scrollable-area-tag {
  width: $listStatus;
  text-align: center;
  height: 82px;
}

.all-list-scrollable-area-actions {
  width: $listActions;
  text-align: center;
  height: 82px;
}

.all-list-scrollable-area-pagination {
  height: 82px;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  justify-content: center;
  // min-width: 1186px;
  width: 100%;
  margin-top: auto;
}

.all-list-filter-container {
  right: 382px;
  top: 117px;
  width: auto;
  position: absolute;
  display: flex;
}

.all-list-search-container {
  right: 60px;
  top: 110px;
  position: absolute;
}

.all-list-table {
  border-collapse: collapse;
  width: 100%;
}
.all-list-table tr th {
  border-bottom: 1px solid #dadada;
}

.all-list-table tr th,
.all-list-table td {
  border-bottom: 1.5px solid #dadada;
  padding: 0px 0px;
}
.all-list-table td:nth-child(1),
.all-list-table th:nth-child(1) {
  width: 52px;
}
.all-list-table td:nth-child(3),
.all-list-table th:nth-child(3) {
  border-right: 1.5px solid #dadada;
}
.all-list-table tbody tr:nth-child(even) {
  background-color: white;
}

.all-list-table tbody tr:nth-child(odd) {
  background: #f7faff;
}

.list-new-line {
  white-space: pre-wrap;
}

.filter-button-area {
  margin: 5px 8px;
}

.filter-title {
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  color: #979797;
}

.filter-badge-icon {
  width: 15px;
  height: 15px;
  border-radius: 7.5px;
  background-color: #4e8dd7;
  padding-top: 3px;

  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 10px;
  line-height: 6px;
  /* identical to box height, or 60% */

  color: #ffffff;

  text-align: center;
}

.label-lines-5 {
  -webkit-line-clamp: 5;
  // textalignvertical: center;
}
