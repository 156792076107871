.barcode-validate-info-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    border: 1px dashed #c2c2c2;
    border-radius: 8px;
}

.barcode-incorrect-selector-title {
    margin-top: 10px;
}

.barcode-correct-title {
    margin-top: 10px;
    color: #4E8DD7;
}

.barcode-validate-separate-line {
    margin-top: 20px;
    height: 1px;
    background-color: #DFDFDF;
}

.barcode-validate-volume-quantity {
    float: left;
    width: 180px;
}

.barcode-validate-dropdown {
    margin-left: 30px;
    display: inline-block;
    width: 175px;
}

.barcode-validate-modal .modal-content {
    width: 1000px !important;
}

.barcode-title-label {
    border-radius: 4px;
    padding-left: 8px;
    padding-top: 4px;
    padding-right: 8px;
    padding-bottom: 4px;
    margin-left: 4px;
    margin-bottom: 0px;
    font-size: 14px;
    font-family: Mulish;
    font-weight: 400;
    line-height: 17.57px;
}

.barcode-title-label-not-mapped {
    color: #D13636;
    background-color: #FBE1E1;
}

.barcode-title-label-mapped {
    color: #178768;
    background-color: #DFF1EC;
}


