@import '../../../utils/Variables.scss';

.afc-data-record-table.all-list-table {

    th:nth-child(4),
    td:nth-child(4) {
        border-right: 1.5px solid #dadada;
    }

    th:nth-child(3),
    td:nth-child(3) {
        border-right: 0px;
    }

    .rank-extra {
        margin-top: 0px;
    }

    .rank-area-extra-width,
    .all-list-scrollable-area-id-container {
        width: 86px;
    }
}