.new-admin-other-body {
  margin-right: 40px;
}

.add-new-admin-group-prompt-modal {
  .modal-content{
    width: 100%!important;
    max-width: 100%!important;
  }

  .modal-dialog.modal-dialog-centered {
    max-width: 100%;
    margin: 0 300px;
  }
}
