.create-step-section-item.customer-information {
    margin-bottom: 10px;
    padding: 10px 30px;
}

.transaction-photo-taking-container {
    display: flex;
    flex-wrap: wrap;
}

.create-step-section-item.transaction-photo-taking-detail {
    width: 735px;
    height: 610px;
    overflow-y: scroll;
    padding: 15px 30px;
}

.transaction-photo-taking-detail-container {
    margin-top: 5px;
    display: flex;
}

.transaction-photo-taking-base-detail {
    width: 390px;
}

.transaction-photo-taking-detail-single-field {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.transaction-photo-taking-detail-single-field-title {
    margin-bottom: 0;
}

.transaction-photo-taking-detail-image {
    width: 267px;
    height: 400px;
}

.create-step-section-item.transaction-photo-taking-recycling-item {
    margin-left: auto;
    margin-right: 0;
    width: 370px;
    padding: 15px 30px;
}

.transaction-photo-taking-single-recycling-item {
    margin-top: 20px;
}

.transaction-photo-taking-single-recycling-item-field-label {
    margin-bottom: 0;
}

.create-section-label.single-recycling-item {
    width: 85px;
}

.customer-information-auth-button {
    position: absolute;
    display: inline-flex;
    align-self: flex-end;
    margin-top: 30px;
}

.customer-information-name {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}

.customer-information-content {
    margin-left: 25px;
}

.customer-information-membership-id {
    margin-left: auto;
    margin-right: 300px;
}

.customer-information-user-label {
    margin-top: 5px;
}

.customer-information-add-user-label {
    border: 1px dashed #c2c2c2;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0px 30px 25px;
}

.customer-information-cancel-save-button {
    margin-top: 10px;
    display: flex;
    position: relative;
}

.customer-infromation-cancel-button {
    margin-left: auto;
    margin-right: 0;
}

.customer-information-save-button {
    margin-right: 0;
}

.label-inline {
    display: inline;
}

.customer-information-user-label-container,
.within-radius-label-container {
    display: flex;
    flex-wrap: wrap;
}

.customer-information-single-user-label {
    position: relative;
    display: flex;
    margin-right: 12px;
    margin-bottom: 10px;
}

.within-radius-label {
    position: relative;
    display: flex;
    margin-top: 4px;
    margin-bottom: 8px;
    border-radius: 4px;
}

.customer-information-single-user-label.color_mustard {
    background-color: #fdd33d;
}

.customer-information-single-user-label.color_teal {
    background-color: #3ff693;
}

.customer-information-single-user-label.color_carbon {
    background-color: #dfe1e6;
}

.within-radius-label.color_teal_4 {
    background-color: #BFFCDB;
}

.within-radius-label.color_sunset_4 {
    background-color: #FFCEC3;
}

.within-radius-label-text {
    margin: 4px 12px 4px 12px;
}

.within-radius-label-text.color_teal_4 {
    color: #02432C;
}

.within-radius-label-text.color_sunset_4 {
    color: #FF0000;
}

.label-with-background-color-label {
    margin: 4px 30px 4px 12px;
}

.customer-information-edit-label-amount {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
}

.label-with-background-color-button {
    height: 16px;
    width: 22px;
    background-color: #ffffff;
    border-color: #d9d9d9;
    border-width: 1px;
    border-radius: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
}

.label-with-background-color-button:focus,
.label-with-background-color-button:hover {
    background-color: #ffffff;
    border-color: #d9d9d9;
}

.label-with-background-color-button-image {
    display: flex;
    height: 7px;
    width: 7px;
    margin: auto;
}

.barcode-items-item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.barcode-items-item-container label {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: unset;
    color: "#4D5560";
}

.barcode-items-item-container div label {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 5px;
    color: "#404040";
}

.recent-transaction-container {
    width: 100%;
    justify-content: center;
    align-items: center;
}

.recent-transaction-container>div {
    max-width: 1150px;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 30px;
    position: relative;
}

.recent-transaction-container>div>label {
    font-family: Mulish;
    font-size: 20px;
    font-weight: 900;
    line-height: 25px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #002148;
    margin-bottom: 15px;
}

.recent-transaction-items::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.recent-transaction-items {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 20px;
}

.recent-transaction-item {
    border: 1px solid #f5f5f5;
    box-shadow: 0px 0px 10px 0px #02432c26;
    width: 365px;
    min-width: 365px;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.recent-transaction-card-header {
    width: 100%;
    height: 62px;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: space-between;
    align-items: center;
}

.recent-transaction-card-header>div {
    display: flex;
    flex-direction: column;
}

.recent-transaction-card-header>label {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: right;
    color: #02432c;
    margin-bottom: unset;
    max-width: 130px;
}

.recent-transaction-card-header>div {
    display: flex;
    flex-direction: column;
}

.recent-transaction-card-header>div :first-child {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #4d5560;
    margin-bottom: unset;
}

.recent-transaction-card-header>div :last-child {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #1f2622;
    margin-bottom: unset;
}

.recent-transaction-item-body {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    justify-content: space-between;
    align-items: flex-start;
}

.recent-transaction-item-body>div {
    display: flex;
    flex-direction: column;
}

.recent-transaction-item-body>div :first-child {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
}

.recent-transaction-item-recyclable {
    border-radius: 4px;
    border: 1px;
    background: #f5f7fb;
    font-family: Mulish;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #1f2622;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
    max-width: 200px;
}

.recent-transaction-item-body>img {
    width: 120px;
    height: 180px;
}

.recent-transaction-card-bottom {
    border-top: 1px solid #f5f5f5;
    display: flex;
    flex-direction: row;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
}

.recent-transaction-card-bottom label {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #4d5560;
}

.recent-transaction-container>div>img {
    width: 48px;
    height: 48px;
    top: 50%;
    left: -20px;
    position: absolute;
    transform: scaleX(-1);
}

.recent-transaction-container>div>img:last-child {
    width: 48px;
    height: 48px;
    top: 50%;
    right: -20px;
    left: unset;
    position: absolute;
    transform: scaleX(1);
}

.recent-transaction-container>div>img:hover {
    cursor: pointer;
}