.create-brand-section {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 150px;
}

.related-store-name {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #404040;
}

.second-section_description.create-brand-description {
    display: block;
    margin-bottom: 0.5rem;
}

.brand-tag-delete-button {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.create-brand-error-message {
    display: block;
}