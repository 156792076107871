@import '../../../utils/Variables.scss';

.create-campaign-cover-photo-reminder {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $commentColor;
  margin-bottom: 16;
  white-space: pre-line;
}

.featured-exclusive-row {
  margin: 0px !important;
}

.featured-exclusive-row-button {
  width: 19px;
  height: 19px;
  border: 1.5px solid $deepGrey;
  border-radius: 9.23077px;
}

.featured-exclusive-row-tip-label {
  margin-bottom: 0px;
  padding-bottom: 8.77px;
}