.container-common {
  padding-top: 60px;
}

.common-full-height {
  height: 100vh !important;
}

.create-section-title {
  font-family: MuliBlack;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: $darkBlue;
  margin: 0px;
}

.create-section-title-without-upper {
  font-family: Mulish;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.5px;
  color: $darkBlue;
  margin: 0px;
  margin-top: 30px;
}

.error-field,
.error-field:focus,
.error-field:active {
  border-color: #ff0000 !important;
  box-shadow: none !important;
}

.create-section-label {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: $titleColor;
}

.create-section-field-input {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $fieldColor;
  overflow-wrap: anywhere;
}

.create-section-label-bottom-space {
  margin-bottom: 8px;
  margin-top: 30px;
}

.create-section-label-no-top-space {
  margin-top: 0px;
}

.section-short-description {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  color: $fieldColor;
}

.dropdown-menu {
  z-index: 1;
}
