@import '../../utils/Variables.scss';

.create-message-suggest {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #979797;
}

.text-input-area {
  height: 200px;
}
.title-message-input {
  width: 100%;
}
