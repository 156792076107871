.tips-message.mission-rewards-error {
  color: #ff0000,
}

.reward-detail-container {
  border-top: 2px solid #DADADA;
  margin-top: 10px;
}

.no-top-border {
  border-top: none;
  margin-top: 0;
}