@import '../../utils/Variables.scss';
.custom-markdown-area-title {
  background: white;
  border: 1px solid $lightGreyTwo;
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding-left: 12px;
  max-width: 700px;
}

.custom-markdown-area-title-with-count-input {
  border: none;
  width: 100%;
  padding: 0;
}

.custom-markdown-area-title-short {
  max-width: 619px;
}

.custom-markdown-area-title:active,
.custom-markdown-area-title:focus {
  border: 1px solid #2b84ee;
  box-shadow: 0px 0px 8px rgba(43, 132, 238, 0.5);
  outline: 0px;
}

.custom-markdown-area-title-with-count-input:active,
.custom-markdown-area-title-with-count-input:focus {
  border: none;
  box-shadow: none;
  outline: 0px;
}

.custom-markdown-area-title-with-count {
  position: relative;
  width: 100%;
  max-width: 700px;
  padding: 0px 12px;
  height: 40px;
  background: white;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid $lightGreyTwo;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-flex;
}

.custom-markdown-area-title-with-count:active,
.custom-markdown-area-title-with-count:focus-within {
  border: 1px solid #2b84ee;
  box-shadow: 0px 0px 8px rgba(43, 132, 238, 0.5);
  outline: 0px;
}

.custom-markdown-area-title-count {
  flex: none;
  margin: 8px 0 8px 5px;
  opacity: 0.45;
}

.custom-markdown-area-content-count {
  width: 100%;
  max-width: 700px;
  position: relative;
}

.custom-markdown-area-content-count-number {
  position: absolute;
  right: 12px;
  top: 2px;
  opacity: 0.45;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}