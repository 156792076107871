.save-success-toast-container {
  width: calc(100% - 240px);
  max-width: calc(100% - 240px);
  position: fixed;
  right: 0px;
  align-items: center;
  top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  .save-toast-main-content {
    max-width: 386px;
  }
  .save-toast-body {
    background: rgba(0, 33, 72, 0.9);
    border-radius: 8px;
    padding: 14px 20px;
    display: flex;
  }
  .save-toast-text {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #ffffff;
    flex: none;
    order: 0;
    align-self: center;
    margin-bottom: 0px;
    max-width: 300px;
    min-width: 300px;
    width: 300px;
    text-overflow: ellipsis;
    margin-right: 10px;
  }
  .save-toast-button {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #64abff;
    flex: none;
    order: 1;
    align-self: center;
    margin-bottom: 0px;
    &:hover {
      cursor: pointer;
    }
  }
}
