.feedback-container {
  margin-left: 40px;
  margin-right: 40px;

  .feedback-relevant-images-container {
    flex-direction: row;
  }
  .feedback-relevant-image,
  .feedback-relevant-image:hover {
    width: 150px;
    height: 150px;
    object-fit: contain;
    margin-right: 20px;
    cursor: pointer;
  }
}
