.barcode-code {
    font-family: Mulish;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: rgb(64, 64, 64);
    background: #f5f5f5;
    height: 52px;
    width: 336px;
    border-radius: 4px;
    margin-right: 8px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding-left: 28px;
    padding-right: 20px;
    margin-bottom: 20px;
}

.barcode-code img {
    width: 30px;
    height: 30px;
}

.barcode-code label {
    margin-bottom: unset;
}

.barcode-code img:hover {
    cursor: pointer
}

.barcode-tick-icon {
    width: 24px;
    height: 24px;
    align-self: center;
}

.barcode-volume {
    display: flex;
    width: 350px;
}

.barcode-volume input{
    width: 200px;
}