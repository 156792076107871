.reward-sku-title {
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  display: flex;

  .create-section-title-without-upper {
    margin-top: 0px;
  }

  .reward-sku-delete-button,
  .reward-sku-delete-button:hover {
    cursor: pointer;
  }
}
.sku-pattern-title {
  display: flex;
  flex-direction: column;
}
