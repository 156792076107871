.mission-trigger-page-title {
  margin-top: 10px;
}

.no-bottom-space {
  margin-bottom: 0;
}

.grid-container {
  display: grid;
  grid-template-columns: 488px 156px 156px;
  column-gap: 40px;
}

.margin-top-8 {
  margin-top: 8px;
}

.custom-title-label-dimmed-color {
  opacity: 0.5;
}

.custom-title-input-dimmed-length {
  max-width: 488px;
}