.customer {
  .scroll-container-common-header {
    justify-content: flex-start;
  }

  .scroll-container-long-screen {
    width: '100%';
  }

  // .detail-info-section-item {
  //   padding-top: 0px;
  // max-width: 860px;
  // }

  .detail-record-section-item {
    //   padding-top: 0px;
    // width: 995px;
    padding-bottom: 0px;
  }

  .custom-card-area {
    //   background: #ffffff;
    //   box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
    //   border-radius: 8px;
    max-width: 860px;
  }

  .custom-record-table {
    margin-top: 30px;
  }

  .custom-record-table th,
  .custom-record-table td {
    border: 1px solid #dadada;

    font-family: Muli;
    font-style: normal;

    color: #404040;
    white-space: pre-wrap;
  }

  .custom-record-blod-font {
    font-family: Muli;
    font-style: normal;

    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
  }

  .custom-record-normal-font {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 40px;
  }

  .custom-record-table th {
    padding: 12px 20px;
  }

  .custom-record-table td {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    text-align: center;

    white-space: pre-wrap;
    height: 46px;
  }

  .custom-timeline-area {
    display: flex;
  }

  .custom-timeline-icon {
    display: grid;
    width: 40px;
    padding-top: 20px;
  }

  .custom-timeline-line {
    padding: 12px 6px 0px 6px;
  }

  .custom-timeline-font-color {
    color: #4d5560;
  }

  .custom-timeline-time-color {
    color: #404040;
  }

  .show-more-button {
    width: 82px;
    margin: 0px;
    padding: 0px;
  }

  .view-all-button {
    width: 282px;
    align-self: flex-end;
    margin-top: 31px;
    margin-bottom: 30px;
  }

  .no-data-area {
    padding: 30px 0px;
  }

  .edit-custom-transaction-selector {
    width: 220px;
  }

  .dropdown-short {
    width: 180px;
  }

  .dropdown-input-long {
    width: 355px;
  }

  .dropdown-mutiple-add-button {
    width: fit-content;
    margin-left: auto;
    margin-top: 10px;
  }
}

.custom-add-remove-mutiple-selector {
  display: inline-flex;
  flex-wrap: wrap;
}

.custom-add-remove-mutiple-selector-buttons {
  display: flex;
  max-height: 202px;
}
.custom-add-remove-mutiple-selector-buttons-line-break {
  width: 100%;
  height: 60px;
  .flex-row {
    height: 100%;
    max-width: 330px;
    width: 100%;
    justify-content: center;
    margin-left: -20px;
  }
  img {
    transform: rotate(90deg);
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 30px;
  }
}

.custom-add-remove-mutiple-selector-buttons-area {
  margin-right: 10px;
  align-self: center;
}

.custom-add-remove-mutiple-selector-buttons-area button {
  margin-top: 5px;
}

.custom-add-remove-mutiple-selector .option-and-add-button {
  max-width: 330px;
  width: 100%;
}

.disabled-opacity {
  opacity: 0.5;
}

.custom-input-with-title::placeholder {
  color: #8C8C8C;
}

.transaction-general-purchase-detail-separete-line {
  width: 100%;
  height: 1px;
  background-color: #DFDFDF;
  margin-top: 25px;
}

.transaction-general-purchase-detail-separete-title {
  font-family: MuliBlack;
  font-weight: 900;
  color: #002148;
  letter-spacing: 0.5px;
}

.transaction-general-purchase-detail-sub-type-title {
  font-family: MuliBlack;
  color: #002148;
}

.transaction-general-purchase-detail-title-color {
  color: #6AB3FF;
}

.transaction-general-purchase-detail-margin-top-0 {
  margin-top: 0;
}

.membership-info-card-tr {
  vertical-align: top;
}

.duplicate-ocl-yes-button{
  background-color: #FFE6E1;
  color: #FF6B4A;
  border-radius: 4px;
  border: none;
}

.duplicate-ocl-no-button{
  background-color: #DFF1EC;
  color: #178768;
  border-radius: 4px;
  border: none;
}

.membership-info-editable-field-description {
  margin-top: -4px;
  margin-bottom: 16px;
}