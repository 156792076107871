.store-selection-modal {
  padding-left: 0;
  padding-right: 0;
}
.store-selection-header {
  padding-left: 40px;
  padding-right: 40px;
}
.store-buttons {
  padding-left: 40px;
  padding-right: 40px;
}
.store-content-padding {
  padding-left: 28px;
  padding-right: 28px;
  width: 100%;
}
.store-search-container {
  height: 68px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dadada;
  .select-label {
    margin-bottom: 0;
    margin-left: 12px;
  }
  #select-all {
    margin-top: 0;
  }
  .left-container {
    display: flex;
    min-width: 100px;
  }
  .right-container {
    display: flex;
    flex: 1;
    max-width: 800px;
    justify-content: flex-end;
  }
  .dropdown-menu-container {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
    border-radius: 8px;
    padding: 0 12px;
  }
  .dropdown-item {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #404040;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 15px 0px;
  }
}
.store-fitler-container {
  background: #ffffff;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0px 12px;
  flex: 1;
  max-width: 450px;
  display: inline-flex;
  margin-right: 35px;
  height: 40px;
}
.store-filter-icon {
  width: 16px;
  height: 16px;
  align-self: center;
  display: flex;
  margin-right: 10px;
}
input.list-filter-search-field.search-field-input {
  margin-left: 0;
}
.store-selection-list-container {
  width: 80vw;
  height: 40vh;
}
.store-row {
  display: flex;
  align-items: center;
  .store-info-container {
    margin-left: 14px;
    .store-name {
      font-family: Mulish;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #404040;
    }
    .store-id {
      font-family: Mulish;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #979797;
    }
  }
  .store-row-checkbox {
    margin-top: 0;
  }
}
.ReactVirtualized__List:focus {
  outline: none;
}
.campaign-edit-loading {
  width: 80vw;
  height: 40vh;
}
